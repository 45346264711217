import classNames from 'classnames';

import { BodyText, Headline, Icon, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	bem,
	FORM_ID,
	LOCK_ICON,
} from '../../constants';

export function LoginForm(props) {
	const {
		children,
		headerText,
		instructionText,
		isOnboardingUser,
		userId,
		username,
	} = props;

	const formClasses = classNames(
		bem.className(),
		'BhrForms',
		'js-lockScreenForm',
	);

	const mobileFriendlyEnabled = isOnboardingUser;
	const iconWrapperClasses = classNames({
		'phoneOnly phoneLockModal phoneLockModal--flex': mobileFriendlyEnabled,
	});

	return (
		<form
			className={ formClasses }
			id={ FORM_ID }
			onKeyDown={ (e) => {
				if (e.key === 'Escape') {
					e.stopPropagation();
				}
			} }
			onSubmit={ e => e.preventDefault() }
			tabIndex="-1"
		>

			{/* Need to manually add icon to modal for when modal has mobile styles */}
			{ mobileFriendlyEnabled &&
				<div className={ iconWrapperClasses }>
					<Icon brand={ true } name={ LOCK_ICON } />
				</div>
			}

			{ifFeature('encore', <LayoutBox marginBottom={1}><Headline color='neutral-strong' component='h4'>{ headerText }</Headline></LayoutBox>, <h3 className={ bem.elem('header') }>{ headerText }</h3>)}
			{ifFeature('encore', <LayoutBox marginBottom={3}><BodyText color='neutral-extra-strong' justify='center'>{ instructionText }</BodyText></LayoutBox>, <p className={ bem.elem('instruction') }>{ instructionText }</p>)}

			{ children }

			<input type="hidden" id="userid" name="userid" value={ userId } />
			<input type="hidden" id="username" name="username" value={ username } />

		</form>
	)
}
