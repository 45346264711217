import { ifFeature } from '@bamboohr/utils/lib/feature';
import { PASSWORD_TYPE } from 'manage-session.mod/constants';

import { PasswordLoginForm } from './components/password-login-form.react';
import { SamlLoginForm } from './components/saml-login-form.react';
import { FORM_ID, LOCK_ICON } from './constants';

export const openLockModal = (props) => {
	const { onOpen, primaryAction, urlPrefix, ...rest } = props;

	const mobileFriendlyEnabled = props.isOnboardingUser;
	const hasSso = props.formType !== PASSWORD_TYPE;
	const Form = hasSso ? SamlLoginForm : PasswordLoginForm;

	const modalOptions = {
		alternativeAction: () => {
			document.location.href = `${urlPrefix}login.php?logout=1`;
		},
		alternativeActionText: $.__('Log Out'),
		content: <Form ssoButtonClickAction={hasSso ? primaryAction : null} {...rest} />,
		hasCloseButton: false,
		header: false,
		icon: LOCK_ICON,
		iconV2Color: 'primary-strong',
		isHeadless: true,
		isMobileFriendly: mobileFriendlyEnabled,
		isMobileFriendlySheetFullScreen: mobileFriendlyEnabled,
		isOpen: true,
		primaryAction,
		primaryActionKey: 'Enter',
		primaryActionText: $.__('Log In'),
		onOpen: () => {
			if (!hasSso) {
				$('.js-passwordInput').focus();
			} else {
				/**
				 * If `hasSso` is true, we don't want to show the modal action bar so we are manually hiding it using jQuery.
				 *
				 * Note: There currently isn't a way to exclude modal actions outside of setting the modal `type` to "custom". This, however, presents an issue when the lock modal needs to be displayed as a Sheet. You can't have a Sheet with a "custom" type.
				 *
				 * This logic can be removed once we update the global-modal to use the new Fabric Modal component.
				 */
				$(`#${FORM_ID}`).parent().next().hide();
			}
		},
		type: 'small',
	};
	window.BambooHR.LockModal.setState(modalOptions);
};

export const closeLockModal = () => {
	window.BambooHR.LockModal.setState({ isOpen: false });
};
